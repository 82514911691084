.App {
	
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
	animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ddd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
	transform: rotate(0deg);
  }
  to {
	transform: rotate(360deg);
  }
}

.logo img {
	width: 100px;
	margin-top: 15px;
	margin-bottom: 2vh;
	text-align:center;
}

.logocontainer {
	margin-left: auto;
	margin-right: auto;
	display: block;
	width: 50%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.box {
	background-color: #f7f7f7;
	max-width: 95vw;
	width: 350px;
	padding: 20px;
	font-size: 18px;
	font-weight:bold;
	color: 'black';
	padding-bottom: 20px;
	border-radius: 6px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.roleButton {
	padding: 20px;
	margin: 5px;
	font-size: 16px;
	border-radius: 3px;
	border: 1px solid #f0f0f0;
	cursor:pointer;
	color: #444;
}

.roleButton.inactive:hover {
	background-color: rgba(0,0,0,0.1)
}

.tjenester {


}

.tjeneste {
	float:left;
	display:inline;
	margin-right: 6vw;
}